<template>
  <div class="coupon">
    <div class="coupon--header">
      <div
        v-if="coupon.name"
        class="s-px-3 s-text-2xl s-font-bold text-truncate"
      >
        {{ coupon.name }}
      </div>
      <div class="s-mt-2">
        <p>使用期限：</p>
        <time> {{ startToEnd }}</time>
      </div>
    </div>

    <div class="coupon--wrapper">

    <div class="coupon--image">
      <img
        v-if="coupon && coupon.img_url"
        :src="coupon.img_url"
      />
    </div>
    <div class="coupon--body">
    </div>

    <div class="coupon--footer">
      <div class="coupon--line mb-2"></div>
      <div class="s-mb-4 s-mt-3" v-if="showConfirmButton">
        <div class="coupon-confirm mb-1">確認使用後將無法復原，請您確認。</div>
        <button
          class="s-btn s-btn-bg-primary s-btn-xs"
          type="button"
          @click="handleConfirm"
          :disabled="coupon.used_date || isExpired"
        >
          {{ confirmButtonText }}
        </button>
      </div>
      <div
        v-if="
          coupon && coupon.description
        "
        class="s-mb-4 s-px-3 coupon--desc"
        style="white-space: pre-line"
        v-html="coupon.description"
      >
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import "bootstrap-vue/dist/bootstrap-vue.css";
import { format } from "date-fns";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import moment from "moment";

export default {
  data() {
    return {
      coupon: {}
    };
  },

  computed: {
    showConfirmButton() {
      return true
    },
    confirmButtonText() {
      if (this.coupon.used_date) {
        return "已使用";
      } else if (this.isNotYet) {
        return "尚未開始";
      } else if (this.isExpired) {
        return "已過期";
      } else {
        return "確認使用";
      }
    },
    isNotYet() {
      if (!this.coupon.start_at) return false;
      // 票卷結束時間在今天之前 => 已經結束
      return moment().isBefore(new Date(this.coupon.start_at), 'day');
    },
    isExpired() {
      if (!this.coupon.end_at) return true;

      // 票卷開始時間在今天之後 => 尚未開始
      return moment().isAfter(new Date(this.coupon.end_at), 'day');
    },
    startToEnd() {
      let startAt = this.coupon?.start_at ?? false;
      let endAt = this.coupon?.end_at ?? false;

      if (!startAt && !endAt) return "";

      let startAtText = startAt
        ? format(new Date(startAt), "yyyy/MM/dd")
        : "無期限";
      let endAtText = endAt
        ? format(new Date(endAt), "yyyy/MM/dd")
        : "無期限";

      if (startAt == endAt) {
        return startAtText;
      }

      return `${startAtText} ~ ${endAtText}`;
    },
  },

  async mounted() {
    await this.fetchCoupon();
  },

  methods: {
    format,
    async fetchCoupon() {
      await abbottAndApi.getCouponDetail(
        this.$route.params.orgCode,
        {
          coupon_id: this.$route.params.id
        }).then((res) => {
        this.coupon = res.data.data;
      });
    },
    handleConfirm() {
      this.$router.push({
        name: "LiffProjAbbottAndCouponSelectItem",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../node_modules/font-awesome/css/font-awesome.min.css";
.coupon {
  display: flex;
  flex-direction: column;
  text-align: center;

  box-sizing: content-box;


  border-radius: 10px;
  background-size: 100% 100% ;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 50% 101%, rgba(white,0) 1.5em, white (1.6em));
  background-position: top left, top right;
  filter: drop-shadow(0 2px 8px rgba(black,.3));
  padding-bottom: 1.5em;



  &--header {
    background: var(--liff-header-color);
    color: var(--liff-header-text-color);
    padding: 15px 10px 10px 10px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-top: 1px solid var(--liff-header-text-color);
    border-left: 1px solid var(--liff-header-text-color);
    border-right: 1px solid var(--liff-header-text-color);
  }

  &--barcode {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  &--wrapper{
    overflow-y: scroll;
  }

  &--image {
    margin: 12px;

    img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &--body {
    padding: 0 30px;
    margin: 0 12px 12px 12px;
  }

  &--footer {
    padding: 0 24px 12px;

    .coupon-confirm {
      color: #ea0000;
      font-size: 14px;
    }
  }

  &--copy-panel {
    background: #f2f2f7;
    border-radius: 10px;
    padding: 12px 8px;
    position: relative;
  }

  &--copy-button {
    background: rgba(102, 153, 204, 0.03);
    border: 1px solid rgba(102, 153, 204, 0.6);
    color: #3973ac;
    padding: 4px 16px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

  &--line {
    border: 1px dashed #dadada;
  }

  button {
    font-size: 16px;

    &.s-btn-bg-primary {
      background: var(--liff-button-color);
      color: var(--liff-button-text-color);
    }
  }
}
</style>
<style scoped>
.coupon--desc {
  text-align: left;
  font-size: 0.9rem;
  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0 2px 8px 0;
  border-radius: 5px;
  padding: 10px 15px;
}
</style>
