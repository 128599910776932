import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
      redeemData: "projAbbottAnd/redeemData",
      user: "liffAuth/user",
    }),
  },
  methods: {
    ...mapActions('projAbbottAnd', ['setIsMember', 'setIsBindStore']),
    async joinChannel(channel) {
      try {
        await abbottAndApi.joinChannel(
          this.$route.params.orgCode,
          {
            channel: channel,
            brand: this.queryData.brand,
            coupon_code: this.queryData.coupon_code
          }
        );
      } catch (e) {
        alert("綁定錯誤");
      }
    },
    formatQueryData() {
      let query = this.queryData;
      switch (this.couponType) {
        case "j5":
        case "j5-1":
          query.coupon_code = "WI_001";
          break;
        default:
          break;
      }
      return query;
    },
    async checkStoreCode() {
      try {
        const { data } = await abbottAndApi.checkStoreCode(
          this.$route.params.orgCode,
          {
            store_code: this.queryData.store_code,
          }
        );
        return data.data.is_exist;
      } catch (e) {
        alert("店家代碼錯誤");
        return false;
      }
    },
    async validate() {
      switch (this.couponType) {
        case "j4":
          return this.checkStoreCode();
        default:
          return true;
      }
    },
    async submit() {
      if (!this.validate()) {
        alert("不符合活動資格");
        return;
      }
      const query = this.formatQueryData();
      try {
        await abbottAndApi.sendCoupon(this.$route.params.orgCode, {
          ...query,
        });
        this.$router.push({
          name: "LiffProjAbbottAndThanks",
          query: {
            messages: "成功領取",
          },
        });
      } catch (e) {
        this.$router.push({
          name: "LiffProjAbbottAndThanks",
          query: {
            messages: e.response.data.message,
          },
        });
      }
    },
    async checkMember() {
      switch (this.couponType) {
        case "ipd":
          try {
            const data = await abbottAndApi.checkIsSendable(
              this.$route.params.orgCode,
              {
                coupon_code: this.queryData.coupon_code,
                qrcode: this.queryData.qrcode,
                event_name: this.queryData.event_name,
                brand: this.queryData.brand,
              }
            );

            if (!data.data.is_sendable) {
              alert("已領取過");
              return;
            }

            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {}
            );

            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action:
                    this.$route.query.redirect_action ??
                    "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                },
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode);

              this.$router.push({
                name: "LiffProjAbbottAndBranchPicker",
              });
            }
          } catch (e) {
            console.log(e);
            alert("資料有錯請洽客服");
          }
          break;
        case "j4":
        case "j5":
        case "j5-1":
          try {
            const res = await abbottAndApi.checkMember(this.$route.params.orgCode, {});
            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action:
                    this.$route.query.redirect_action ??
                    "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                },
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode, {});

              const res = await abbottAndApi.checkMember(this.$route.params.orgCode,{});

              this.setIsMember(res.data.is_member)
              this.setIsBindStore(res.data.is_bind_store)

              if (res.data.is_bind_store) {
                this.$router.push({
                  name: "LiffProjAbbottAndSendCoupon",
                });
              } else {
                this.$router.push({
                  name: "LiffProjAbbottAndBranchPicker",
                });
              }
            }
          } catch (e) {
            console.log(e);
            alert("資料有錯請洽客服");
          }
          break;
        default:
          try {
            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {
                phone: this.queryData.phone,
              }
            );

            this.setIsMember(res.data.is_member)
            this.setIsBindStore(res.data.is_bind_store)

            if (res.data.is_member) {
              if (res.data.line_id == this.user.outer_code) {
                this.$router.push({
                  name: "LiffProjAbbottAndSendCoupon",
                });
              } else {
                alert("此手機與查詢會員不一致，請聯絡客服人員");
              }
            } else {
              this.$router.push({
                name: "LiffProjAbbottAndSendCoupon",
              });
            }
          } catch (e) {
            console.log(e.response.data.message);
            alert("資料有錯請洽客服");
          }
          break;
      }
    },
    async confirmCoupon() {
      try {
        const res = await abbottAndApi.redeemCoupon(
          this.$route.params.orgCode,
          {
            coupon_id: this.redeemData.coupon_id,
            verify_code: this.redeemData.verify_code,
            store_code: this.redeemData.store_code,
            item_id: this.redeemData.item.id,
          }
        );
        this.$router.push({
          name: "LiffProjAbbottAndCouponRedeemSuccess",
          query: {
            qrcode1: res.data.qrcode1,
            qrcode2: res.data.qrcode2,
          },
        });
      } catch (error) {
        alert("兌換失敗，請洽客服人員");
      }
    },
  },
};
